import { Grid, IconButton, Typography } from '@mui/material'
import { Controls } from 'assets/images/Controls'
import React, { FC, useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Shared } from 'ui/shared'
import { useChecklist } from './Checklist-ViewModel'

export const Checklist: FC = () => {
  const { classes } = useStyles()
  const vm = useChecklist()
  const { closeModal, checklist, rent } = vm

  const checklistItems = useCallback(
    (item: any) => {
      return (
        <Grid
          container
          justifyContent={'space-between'}
          direction={'column'}
          display={'flex'}
          style={{
            maxHeight: 50,
            marginBottom: 8,
          }}
          key={item.id}
        >
          <Typography
            style={{
              display: 'flex',
              marginTop: 8,
              width: '40%',
              fontWeight: '400',
              fontSize: 18,
            }}
            color={'#3E3E3E'}
          >
            {item.title}
          </Typography>
          <Grid item className={classes.radioButtonContainer}>
            <Grid className={classes.radioButtonBorder}>
              {item.status === true ? <Grid className={classes.radioButtonContent} /> : null}
            </Grid>
          </Grid>
          <Grid item className={classes.radioButtonContainer}>
            <Grid className={classes.radioButtonBorder}>
              {item.status === false ? <Grid className={classes.radioButtonContent} /> : null}
            </Grid>
          </Grid>
        </Grid>
      )
    },
    [checklist],
  )

  return (
    <Shared.Card style={{ width: 1000, overflowY: 'auto', maxHeight: '100%' }}>
      <Grid container spacing={1} style={{ padding: 16 }}>
        <Grid
          container
          justifyContent={'space-between'}
          direction={'row'}
          style={{ marginBottom: 16 }}
          display={'flex'}
        >
          <Typography style={{ color: 'orange', fontWeight: '700', fontSize: 24 }}>{'Checklist'}</Typography>
          <IconButton onClick={closeModal}>
            <Controls.Close style={{ color: '#3C3B3B' }} />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={1}
          direction={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          style={{ marginBottom: 16 }}
        >
          <Grid
            container
            justifyContent={'space-between'}
            direction={'column'}
            display={'flex'}
            style={{
              maxHeight: 50,
              marginTop: 8,
            }}
          >
            <Typography style={{ display: 'flex', verticalAlign: 'center', marginTop: 8, width: '38%' }}>
              {''}
            </Typography>
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Controls.Like />
              <Typography color={'#797979'} style={{ fontWeight: '400', fontSize: 12 }}>
                {'Ok'}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Controls.Dislike />
              <Typography color={'#797979'} style={{ fontWeight: '400', fontSize: 12 }}>
                {'Broken'}
              </Typography>
            </Grid>
          </Grid>
          {checklist.map((item: any) => checklistItems(item))}
        </Grid>
      </Grid>
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  radioButtonContainer: {
    justifyContent: 'center',
    padding: 16,
    alignItems: 'center',
    display: 'flex',
  },
  radioButtonBorder: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonContent: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: 'black',
  },
}))
