import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

export const usePhoto = () => {
  const dispatch = useDispatch()
  const rent = useSelector(({ modals }: TState) => modals.data)
  const [choosen, setChoosen] = useState<number>(
    rent.startCheck.parameters.filter((item: any) => item.answer === undefined)[0].orderIndex,
  )

  const pageToOrderIndexMap = rent.startCheck.parameters
    .filter((item: any) => item.answer === undefined)
    .reduce((acc: any, item: any, index: any) => {
      acc[index + 1] = item.orderIndex
      return acc
    }, {})

  const handlePageChange = (event: any, page: any) => {
    const orderIndex = pageToOrderIndexMap[page]
    setChoosen(orderIndex)
  }

  const closeModal = () => {
    dispatch(Actions_Trailers.store.showPhoto())
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    closeModal,
    choosen,

    rent,
    handlePageChange,
  }
}
