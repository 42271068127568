import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TChecklistState } from 'redux/modules/rents/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

export const useChecklist = () => {
  const dispatch = useDispatch()

  const rent = useSelector(({ modals }: TState) => modals.data)
  const checklistFromServer = useSelector((state: TState) => state.rents.checklistParams)
  const [checklist, setChecklist] = useState<TChecklistState[]>([])
  const { i18n } = useTranslation()
  const lang = i18n.language.substring(0, 2)

  useEffect(() => {
    const check =
      checklistFromServer !== null
        ? checklistFromServer
            .filter((item) => item.type === 'question')
            .map((item: any) => {
              return {
                id: item.orderIndex,
                title: item.title[lang],
                status: rent.startCheck.parameters.find((i: any) => i.orderIndex === item.orderIndex).answer,
              }
            })
        : []
    setChecklist(check)
  }, [dispatch, rent, checklistFromServer, lang])

  const closeModal = () => {
    dispatch(Actions_Trailers.store.showChecklist())
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    closeModal,
    checklist,
    rent,
  }
}
